import Axios from "axios";
import { message } from "antd";
import db from "../../../database";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import i18next from "i18next";
import * as Sentry from "@sentry/react";
import upsertPOSLog from "../Retail/posLog";

export const createCustomer = async (
  data,
  cart,
  setCart,
  form,
  tillLayout,
  closeCustomerSearch,
  tillData,
  posLogActivity,
  setKioskUI,
  setDisplayAddNewCustomer,
  UAECustomerForm
) => {
  let setAuthTokens;
  const t = i18next.t;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }

  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const serverUrl = process.env.REACT_APP_serverUrl;
  const newCustomerId = uuidv4().replace(/-/g, "").toUpperCase();
  const newRequest = {
    query: `mutation {
        upsertB2CCustomer(
          id: "${newCustomerId}"
          type: "New"
          customer: {
            csBunitId: "${tillData.tillAccess.csBunit.csBunitId}",
            createdby: "${tillData.tillAccess.csUserId}",
            updatedby: "${tillData.tillAccess.csUserId}",
            code: ${data.mobile ? `"${data.mobile.toLowerCase()}"` : null},
            name: ${data.name ? `"${data.name}"` : null},
            mobileNo: ${data.mobile ? `"${data.mobile}"` : null},
            email: ${data.email ? `"${data.email}"` : null},
            pincode: ${data.pincode ? data.pincode : null},
            sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
            finPaymentmethodId: null,
            retlLoyaltyBalance: null,
            iscredit: false,
            b2cRegisteredstoreId: "${tillData.tillAccess.csBunit.csBunitId}",
            firstName: "${data.name}",
            lastName: ${data.lastName ? `"${data.lastName}"` : null},
            gender: ${data.gender !== "" && data.gender !== undefined ? `"${data.gender}"` : null},
            taxId: ${data.taxID ? `"${data.taxID}"` : null},
            anniversaryDate: ${data.anniversaryDate !== undefined && data.anniversaryDate !== null ? `"${moment(data.anniversaryDate).format("YYYY-MM-DD")}"` : null},
            birthday: ${data.BirthdayDate !== undefined && data.BirthdayDate !== null ? `"${moment(data.BirthdayDate).format("YYYY-MM-DD")}"` : null},
            lastVisitDate: ${data.lastVisitDate !== undefined && data.lastVisitDate !== null ? `"${moment(data.lastVisitDate).format("YYYY-MM-DD")}"` : null},
            noOfVisits: 0,
            validity: ${moment(data.validity).format("YYYY-MM-DD") ? `"${moment(data.validity).format("YYYY-MM-DD")}"` : null},
            regType: ${data.type ? `"${data.type}"` : null},
            cardNumber: ${data.cardNumber ? `"${data.cardNumber}"` : null},
            lastBillingAmount: 0,
            averageBasketValue: 0,
            loyaltyPointToExpire: null,
            loyaltyLevel: { cwrLoyaltyLevelId:  ${data.program !== "" && data.program && data.loyality === true ? `"${data.program}"` : null} }
            customerAddress: {
              line1: ${data.street ? `"${data.street}"` : null},
              line2: ${data.city ? `"${data.city}"` : null},
              city: ${data.city ? `"${data.city}"` : null},
              csRegionId: ${tillData.tillAccess.csBunit.customerAddress.csRegion.csRegionID ? `"${tillData.tillAccess.csBunit.customerAddress.csRegion.csRegionID}"` : null},
              csCountryId: ${tillData.tillAccess.csBunit.customerAddress.csCountry.csCountryID ? `"${tillData.tillAccess.csBunit.customerAddress.csCountry.csCountryID}"` : null},
            }            
          }
        ) {
          cwrCustomerId
          code
          name
          email
          mobileNo
          pincode
          retlLoyaltyBalance
          b2cRegisteredstoreId
          lastVisitDate
          noOfVisits
          lastBillingAmount
          averageBasketValue
          loyaltyPointToExpire
          firstName
          lastName
          gender
          taxId
          cardNumber
          regType
          validity
          anniversaryDate
          birthday
          sCustomer {
            sCustomerID
            customerCategory {
              sCustomerCateforyId
              value
              name
              description
            }
          }
          csBunit {
            csBunitId
            name
          }
          loyaltyLevel { 
            cwrLoyaltyLevelId
          }
          customerAddress {
            line1
            line2
            city
            region
            country
            csCountryId
            csRegionId
          }
          status
          message
        }
      }`,
  };
  Sentry.captureException(new Error("Create customer failed"), {
    extra: newRequest,
  });
  try {
    const response = await Axios({
      url: serverUrl,
      method: "POST",
      data: newRequest,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${setAuthTokens}`,
      },
    });

    const result = response?.data?.data?.upsertB2CCustomer;

    if (result?.status === "200") {
      if (tillLayout === 3) {
        localStorage.setItem("kioskUI", 3);
        setKioskUI(3);
        const customEvent = new CustomEvent("customStorageChange", {
          detail: { key: "kioskUI", newValue: 3 },
        });
        window.dispatchEvent(customEvent);
      }

      form.resetFields();
      UAECustomerForm.resetFields();
      setDisplayAddNewCustomer(false);

      const updatedCart = { ...cart, customer: result };
      setCart(updatedCart);
      localStorage.setItem("cartObj", JSON.stringify(updatedCart));

      closeCustomerSearch();
      message.success(result.message);

      const logConfig = await db.logConfiguration.toArray();
      const logCustomerAdd = logConfig.some((config) => config.addCustomer.some((item) => item.log === "Y"));

      if (logCustomerAdd) {
        posLogActivity(result.name, "ACT");
      }
    } else {
      message.error(t("customer_edit_error"));
    }
  } catch (error) {
    Sentry.captureException(error);
    message.error(t("Failed to create new customer. Please check the entered data and try again."));
  }
};

export const updateCustomer = async (
  data,
  cart,
  setCart,
  form,
  closeCustomerSearch,
  selectedEditOldCustomer,
  setSelectedEditOldCustomer,
  setDisplayEditOldCustomer,
  tillData,
  customer,
  UAECustomerForm
) => {
  const t = i18next.t;
  let setAuthTokens;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const serverUrl = process.env.REACT_APP_serverUrl;
  const newRequest = {
    query: `mutation{
              upsertB2CCustomer(id: "${selectedEditOldCustomer.cwrCustomerId}", 
                type:"Old",
                customer: {
                  csBunitId: "${tillData.tillAccess.csBunit.csBunitId}",
                  createdby: "${tillData.tillAccess.csUserId}",
                  updatedby: "${tillData.tillAccess.csUserId}",
                  code: "${customer.code === "2001" ? selectedEditOldCustomer.code : customer.code}"
                  name: "${data.editName}"
                  mobileNo: "${data.editMobile}"
                  email: ${data.editEmail ? `"${data.editEmail}"` : null},
                  pincode: ${data.editPincode ? data.editPincode : null},
                  validity: ${data.validity ? `"${moment(data.validity).format("YYYY-MM-DD")}"` : null},
                  regType: ${data.type ? `"${data.type}"` : null},
                  cardNumber: ${data.cardNumber ? `"${data.cardNumber}"` : null},
                  sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
                  finPaymentmethodId: null,
                  retlLoyaltyBalance: null,
                  iscredit: false,
                  b2cRegisteredstoreId: "${tillData.tillAccess.csBunit.csBunitId}",
                  firstName: "${data.editName}",
                  lastName: ${data.lastName ? `"${data.lastName}"` : null},
                  gender: ${data.gender !== "" && data.gender !== undefined ? `"${data.gender}"` : null},
                  taxId: ${data.taxID ? `"${data.taxID}"` : null},
                  anniversaryDate: ${data.anniversaryDate !== undefined && data.anniversaryDate !== null ? `"${moment(data.anniversaryDate).format("YYYY-MM-DD")}"` : null},
                  birthday: ${data.BirthdayDate !== undefined && data.BirthdayDate !== null ? `"${moment(data.BirthdayDate).format("YYYY-MM-DD")}"` : null},
                  lastVisitDate: ${data.lastVisitDate !== undefined && data.lastVisitDate !== null ? `"${moment(data.lastVisitDate).format("YYYY-MM-DD")}"` : null},
                  noOfVisits: 0,
                  lastBillingAmount: 0,
                  averageBasketValue: 0,
                  loyaltyPointToExpire: null,
                  loyaltyLevel: { cwrLoyaltyLevelId: ${data.program !== "" && data.program && data.loyality === true ? `"${data.program}"` : null} }
                  customerAddress: {
                    line1: ${data.street ? `"${data.street}"` : null},
                    line2: ${data.city ? `"${data.city}"` : null},
                    city: ${data.city ? `"${data.city}"` : null},
                    csRegionId: ${tillData.tillAccess.csBunit.customerAddress.csRegion.csRegionID ? `"${tillData.tillAccess.csBunit.customerAddress.csRegion.csRegionID}"` : null},
                    csCountryId: ${
                      tillData.tillAccess.csBunit.customerAddress.csCountry.csCountryID ? `"${tillData.tillAccess.csBunit.customerAddress.csCountry.csCountryID}"` : null
                    },
                  }            
                }){
                cwrCustomerId
                code
                name
                email
                mobileNo
                pincode
                retlLoyaltyBalance
                b2cRegisteredstoreId
                lastVisitDate
                noOfVisits
                lastBillingAmount
                averageBasketValue
                loyaltyPointToExpire
                firstName
                lastName
                gender
                taxId
                cardNumber
                regType
                validity
                anniversaryDate
                birthday
                sCustomer {
                  sCustomerID
                  customerCategory {
                    sCustomerCateforyId
                    value
                    name
                    description
                  }
                }
                csBunit {
                  csBunitId
                  name
                }
                customerAddress {
                  line1
                  line2
                  city
                  region
                  country
                  csCountryId
                  csRegionId
                }
                loyaltyLevel { 
                  cwrLoyaltyLevelId}
                status
                message
              }
            }`,
  };
  Sentry.captureException(new Error("Update customer failed"), {
    extra: newRequest,
  });
  try {
    const response = await Axios({
      url: serverUrl,
      method: "POST",
      data: newRequest,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${setAuthTokens}`,
      },
    });

    const result = response?.data?.data?.upsertB2CCustomer;

    if (result?.status === "200") {
      form.resetFields();
      UAECustomerForm.resetFields();
      setSelectedEditOldCustomer();
      setDisplayEditOldCustomer(false);
      const updatedCustomer = {
        ...customer,
        firstName: result.firstName,
        lastName: result.lastName,
        name: result.firstName,
        email: result.email,
        customerAddress: result.customerAddress,
        birthday: result.birthday,
        mobileNo: result.mobileNo,
        pincode: result.pincode,
        anniversaryDate: result.anniversaryDate,
        gender: result.gender,
      };

      const updatedCart = { ...cart, customer: updatedCustomer };
      setCart(updatedCart);
      localStorage.setItem("cartObj", JSON.stringify(updatedCart));
      closeCustomerSearch();
      message.success(result.message);
      upsertPOSLog(updatedCart, "CSU");
    } else {
      message.error(t("customer_edit_error"));
    }
  } catch (error) {
    Sentry.captureException(error);
    console.error("Updating customer failed: ", error);
    message.error(t("customer_edit_error"));
  }
};

export const getCustomer = async (form, tillLayout, customerSearchInput, setKioskUI, setCustomerSearchResults, kioskLogin) => {
  let setAuthTokens;
  const authHeaders = await getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const serverUrl = process.env.REACT_APP_serverUrl;
  let query;
  let validation;
  let loginType;
  if (tillLayout !== 3) {
    if (
      !/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(customerSearchInput) &&
      !RegExp("^[6-9][0-9]{9}$")?.test(parseFloat(customerSearchInput)) &&
      !parseFloat(customerSearchInput)
    ) {
      validation = RegExp("^[0-9a-zA-Z]{1,20}$");
      loginType = "name";
    }
  }

  if (/^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/.test(customerSearchInput)) {
    loginType = "email";
  }

  if (/^[1-9]\d*$/.test(customerSearchInput)) {
    loginType = "mobile";
  }

  const paramsInput = {
    query: `query{
          customerSearch(searchKey:"${customerSearchInput.toLowerCase()}"){
            cwrCustomerId 
            code   
            name
            email        
            mobileNo
            cardNumber
            regType
            validity
            name
            creditLimit
            pincode
            retlLoyaltyBalance
            b2cRegisteredstoreId
             iscredit
             lastVisitDate
             noOfVisits
             lastBillingAmount
             averageBasketValue
             loyaltyPointToExpire
             firstName
              lastName
               gender
                taxId
            anniversaryDate
             birthday
            loyaltyLevel{
                cwrLoyaltyLevelId
                name
                accumulationRate
                redemptionRate
            }
            sCustomer{
              sCustomerID
              customerCategory{
                sCustomerCateforyId
                value
                name
                description
              }
            }
            customerAddress {
                line1 
                line2 
                city 
                region
                 country 
                 csCountryId
                  csRegionId
            }
            csBunit{
              csBunitId
              name
            }
            b2cCustomerSegment{
              name
              cwrB2CCustomerSegmentId
            }
          }
        }`,
  };
  try {
    const response = await Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${setAuthTokens}`,
      },
    });
    localStorage.setItem("loginType", loginType);
    const customerSearchResults = response?.data?.data?.customerSearch;

    if (customerSearchResults?.length > 0) {
      setCustomerSearchResults(customerSearchResults);

      if (tillLayout === 3) {
        kioskLogin.resetFields();
        setKioskUI(3);
        const customEvent = new CustomEvent("customStorageChange", {
          detail: { key: "kioskUI", newValue: 3 },
        });
        window.dispatchEvent(customEvent);
        localStorage.setItem("kioskUI", 3);
      }
    } else {
      setCustomerSearchResults([]);

      if (tillLayout === 3) {
        form.setFieldsValue({
          [loginType]: customerSearchInput,
        });
        setKioskUI(4);
        const customEvent = new CustomEvent("customStorageChange", {
          detail: { key: "kioskUI", newValue: 4 },
        });
        window.dispatchEvent(customEvent);
        localStorage.setItem("kioskUI", 4);
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    console.error("Customer search failed:", error);
  }
};
