import { Row, Col, Table, Input, Button, Popover, DatePicker, message, Modal } from "antd";
import { CalendarOutlined, DownOutlined, RightOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import Back from "../../assets/images/backIcon.svg";
import React, { useState, useEffect, useRef } from "react";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import moment from "moment";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import Retrieve from "../../assets/images/retrieve.png";
import printerIcon from "../../assets/images/printerIcon.svg";
import clearFilters from "../../assets/images/clearFilters.svg";
import styles from "../../constants/styles.json";
import db from "../../database";

const BOMOrderHistory = (props) => {
  const { showOrderHistory, setShowOrderHistory, colorVariable, CustomerSearch, isPopoverVisible, setPopoverVisible, handleButtonClick, setReverseOrderData,shortcutsListModalOpen, showAltUnderline, setShowAltUnderline } = props;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const [orderHistoryDetails, setOrderHistoryDetails] = useState([]);
  const [searchHistoryInput, setSearchHistoryInput] = useState("");
  const [reverseOrderDetails, setReverseOrderDetails] = useState(false);
  const [selectedReverseOrderDetails, setSelectedReverseOrderDetails] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filtersFlag, setFiltersFlag] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [focusedRowIndex, setFocusedRowIndex] = useState(null);
  const { RangePicker } = DatePicker;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData?.tillAccess?.cwrTill?.hardwareController?.imageUrl;
  const csBunitId = tillData.tillAccess.csBunit.csBunitId;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout4 : {};
  const { t } = useTranslation();
  const pricePrecision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  const qtyRef = useRef(null);
  const rowHeight = useRef(0);

  let setAuthTokens;
  const authHeaders = getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    setAuthTokens = authHeaders.access_token;
  }

  useEffect(() => {
    if (reverseOrderDetails) {
      setTimeout(() => {
        if (qtyRef.current) {
          qtyRef.current.focus();
          qtyRef.current.select();
        }
      }, 0);
    }
  }, [reverseOrderDetails]);

  const handleKeyDown = (e) => {
    const { key, keyCode, altKey, shiftKey } = e;

    if (shortcutsListModalOpen) {
      return;
    }

    if (isPopoverVisible) {
      if (key === "Escape") {
        setPopoverVisible(false);
      }
      return;
    }

    const tableBody = tableBodyRef.current;
    if (!tableBody) return;

    const rows = tableBody.querySelectorAll("tr");
    if (rows.length === 0) return;

    // Handle navigation through rows
    if (key === "ArrowDown") {
      setFocusedRowIndex((prevIndex) => (prevIndex + 1) % rows.length);
    } else if (key === "ArrowUp") {
      setFocusedRowIndex((prevIndex) => (prevIndex - 1 + rows.length) % rows.length);
    }

    // Handle Enter key to expand order history
    else if (key === "Enter" && !altKey) {
      const record = orderHistoryDetails[focusedRowIndex];
      if (record) {
        handleExpand(record);
      }
    }

    // Handle reverse order with Alt+V
    else if (altKey && keyCode === 86) {
      const record = orderHistoryDetails[focusedRowIndex];
      if (record?.ioproducts && record.reverse === "N" && record.ioproducts?.length > 0 && posConfig?.enableReverseProduction === "Y") {
        setReverseOrderDetails(true);
        record.qtyToReverse = 0;
        record.remainingQty = record.qtyproduction - record.reverseQty;

        let uomData = [];
        if (record.uomId) {
          db.productUom.where("csUomId").equals(record.uomId).toArray().then((uom) => {
            if (uom.length > 0) {
              uomData = uom;
            }
          });
        }
        record.uomData = uomData;
        setSelectedReverseOrderDetails([record]);
      }
    }

    // Handle Quantity changes when reverse order is enabled
    if (reverseOrderDetails) {
      if (shiftKey) {
        const record = selectedReverseOrderDetails[0];

        if (key === "=" || keyCode === 187) { // Shift + '+' increases quantity
          handleQtyChange(record.qtyToReverse + 1, record);
        } else if (key === "-" || keyCode === 189) { // Shift + '-' decreases quantity
          handleQtyChange(Math.max(record.qtyToReverse - 1, 0), record);
        }
      }

      if (altKey) {
        if (keyCode === 65) { // Alt + C for Cancel
          setReverseOrderDetails(false);
        } else if (keyCode === 84) { // alt + T for print
          const record = orderHistoryDetails[focusedRowIndex];
          if (record) {
            print(record); // Call the print function for the selected record
          }
        }

        else if (keyCode === 82) { // Alt + R for Reverse
          const record = selectedReverseOrderDetails[0];
          if (record.qtyToReverse <= 0) {
            message.warning("Return Qty can't be more than Stock quantity !");
            return;
          }

          if (record.qtyToReverse <= record.stockOnHand && record.qtyToReverse <= record.remainingQty + record.qtyToReverse) {
            localStorage.setItem("reverseOrderData", JSON.stringify(selectedReverseOrderDetails));
            setReverseOrderData(selectedReverseOrderDetails);
            setShowOrderHistory(false);
            setOrderHistoryDetails([]);
            setSearchHistoryInput("");
            setReverseOrderDetails(false);
            setSelectedReverseOrderDetails([]);
            setDateRange([]);
            setFiltersFlag(false);
            setExpandedRowKeys([]);
          } else {
            message.warning(`${t("sales_return_error")}`);
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [reverseOrderDetails, focusedRowIndex, orderHistoryDetails, posConfig, selectedReverseOrderDetails]);

  useEffect(() => {
    if (tableBodyRef.current) {
      const row = tableBodyRef.current.querySelector('tr');
      if (row) {
        rowHeight.current = row.offsetHeight;
      }
    }
  }, [orderHistoryDetails]);




  useEffect(async () => {
    if (showOrderHistory) {
      let dateValue = [moment(new Date()), moment(new Date())];
      searchOrderHistory("orderDateSearchKey", dateValue, 0, false, true);
    }
  }, [showOrderHistory]);


  let scrollLeft;
  let maxScroll;
  let currentScroll;

  const tableBodyRef = useRef(null);
  const dateRef = useRef(null);

  const fetchMoreData = (event) => {
    const lengthOff = localStorage.getItem("dataLength");
    maxScroll = event.target.scrollHeight - event.target.clientHeight;
    currentScroll = event.target.scrollTop;
    if (scrollLeft !== event.target.scrollLeft) {
      scrollLeft = event.target.scrollLeft;
      return null;
    }
    if (Math.round(currentScroll) >= Math.round(maxScroll) - 1) {
      let orderD = JSON.parse(localStorage.getItem("orderType"));
      if (orderD.length > 0) {
        if (tableBodyRef.current) {
          tableBodyRef.current.scrollTop = Math.round(currentScroll) - 5;
        }
        let dateFlag = true;
        if (dateRef.current.textContent !== "Choose date range") {
          dateFlag = false;
        }
        searchOrderHistory(orderD[0], orderD[1], lengthOff, true, dateFlag);
      }
    }
  };

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (!tableBody) return;

    const rows = tableBody.querySelectorAll('tr');
    if (rows.length === 0) return;

    const rowHeight = rows[0].offsetHeight;
    // Calculate the position of the focused row
    const focusedRow = rows[focusedRowIndex];
    if (focusedRow) {
      const offsetTop = focusedRow.offsetTop;

      // Scroll the table to the focused row position
      tableBody.scrollTop = offsetTop - tableBody.clientHeight / 2 + rowHeight / 2;
    }
  }, [focusedRowIndex]);

  useEffect(() => {
    const antTableBody = tableBodyRef.current;
    if (antTableBody) {
      antTableBody.addEventListener("scroll", fetchMoreData);
      return () => {
        antTableBody.removeEventListener("scroll", fetchMoreData);
      };
    }
  }, []);

  const updateProductWithUOMData = async (product) => {
    let uomData = [];
    if (product.csUomId) {
      await db.productUom
        .where("csUomId")
        .equals(product.csUomId)
        .toArray()
        .then((uom) => {
          if (uom.length > 0) {
            uomData = uom;
          }
        });
    }
    return {
      ...product,
      uomData,
    };
  };

  const searchOrderHistory = async (date, dateValue, row, flag, dateFlag) => {
    let data;
    let dateFilter = null;
    let startDate;
    let endDate;
    const sortOptions = {
      documentno: "desc",
      productName: "desc",
      date: "desc",
    };
    if (date !== "orderDateSearchKey") {
      data = dateValue;
      dateValue = dateRange;
    }
    const filteredSortOptions = Object.fromEntries(Object.entries(sortOptions).filter(([key, value]) => value !== null));
    if (dateValue?.length > 0) {
      localStorage.setItem("orderType", JSON.stringify([date, dateValue]));
      dateFilter = JSON.stringify(JSON.stringify({ startDate: moment(dateValue[0])?.format("YYYY-MM-DD"), endDate: moment(dateValue[1])?.format("YYYY-MM-DD") }));
      startDate = moment(dateValue[0]).format("YYYY-MM-DD");
      endDate = moment(dateValue[1]).format("YYYY-MM-DD");
    }
    const productSearchInput = document.getElementById("sm-salesHistory-customer");
    if (productSearchInput) {
      productSearchInput.focus();
    }
    const dateFiltered = `"{\\"DateRange\\":[\\"${startDate}\\",\\"${endDate}\\"]}"`;
    const sortData = JSON.stringify(filteredSortOptions).replace(/"/g, '\\"');
    const startRow = row;
    const endRow = parseInt(startRow, 10) + 50;
    let orderHistoryData = await Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {productionHistory(
                    q: ${date === "customer" ? (data.trim() ? `"${data?.trim()}"` : null) : searchHistoryInput !== "" && flag ? `"${searchHistoryInput?.trim()}"` : null},
                    filter_by: ${dateValue?.length > 0 ? dateFiltered : null},
                    startRow: "${startRow}",
                    endRow: "${endRow}",
                    sort_by: "${sortData}",
                    bunitId: "${csBunitId}"
                  ) {
                  prOrderId
                  csBunitId
                  bunitName
                  csDoctypeId
                  documentno
                  docstatus
                  date
                  mProductId
                  productName
                  qtyproduction
                  description
                  mBatchId
                  batch
                  salePrice
                  costPrice
                  batchNo
                  upc
                  reverse
                  totalPrice
                  uomId
                  uom
                  ediCode
                  reverseQty
                  productValue
                  stockOnHand
                  ioproducts {
                    prIoproductId
                    type
                    mProductId
                    product
                    productValue
                    csUomId
                    uom 
                    mBatchId
                    actualqty
                    unitcost
                    actualcost
                    line
                    salePrice
                    totalPrice
                    stockOnHand
                  }
                  indirectCost {
                    prProdindirectcostId
                    value
                    cost
                    prIndirectcostId
                    prIndirectcostName
                  }
                }
              }
              `,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${setAuthTokens}`,
      },
    });
    if (orderHistoryData?.data?.data?.productionHistory.length > 0) {
      orderHistoryData.data.data.productionHistory.forEach((order) => {
        order.key = uuidv4().replace(/-/g, "").toUpperCase();
      });
      setFiltersFlag(!dateFlag);
      let historyData = orderHistoryData.data.data.productionHistory;
      const filteredOrders = await Promise.all(
        historyData.map(async (order) => {
          let filteredIoproducts = order?.ioproducts?.filter((product) => product?.type === "PR-");
          if(filteredIoproducts  !== undefined && filteredIoproducts.length>0){
            const modifiedData = await Promise.all(filteredIoproducts?.map((product) => updateProductWithUOMData(product)));
            return {
              ...order,
              ioproducts: modifiedData,
            };
          }else {
            return order;
          }
          
        })
      );

      setOrderHistoryDetails(filteredOrders);
    }
  };

  const orderHistoryColumns = [
    {
      title: "Document No:",
      dataIndex: "documentno",
      key: "documentno",
      width: "12%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "12%",
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      width: "23%",
    },
    {
      title: "Batch No",
      dataIndex: "batchNo",
      key: "batchNo",
      width: "20%",
    },
    {
      title: "Unit Price",
      dataIndex: "salePrice",
      key: "salePrice",
      width: "12%",
      render: (text, record) => {
        return <div style={{ textAlign: "center" }}>{record?.salePrice?.toFixed(pricePrecision)}</div>;
      },
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: "15%",
      render: (text, record) => {
        return <div style={{ textAlign: "center" }}>{record?.totalPrice?.toFixed(pricePrecision)}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "reverse",
      key: "reverse",
      width: "20%",
      filters: [
        {
          text: "Reverse",
          value: "Reverse",
        },
        {
          text: "Order",
          value: "Order",
        },
      ],
      onFilter: (value, record) => {
        const displayText = record.reverse === "N" ? "Order" : "Reverse";
        return displayText.indexOf(value) === 0;
      },
      render: (reverse) => {
        return reverse === "N" ? "Order" : "Reverse";
      },
    },
  ];

  const getDisplayDate = () => {
    if (dateRange && dateRange.length === 2) {
      const [start, end] = dateRange;
      return `${start.format("DD-MM-YYYY")} to ${end.format("DD-MM-YYYY")}`;
    }
    return "Choose date range";
  };

  const content = (
    <RangePicker
      open={isPopoverVisible}
      allowEmpty
      id="sm-salesHistory-date"
      onPressEnter={searchOrderHistory}
      allowClear={true}
      format={"DD-MM-YYYY"}
      suffixIcon={null}
      separator={null}
      value={dateRange}
      onChange={(e) => {
        if (e?.length > 0) {
          setPopoverVisible(false);
          setDateRange(e);
          searchOrderHistory("orderDateSearchKey", e, 0, searchHistoryInput !== "" ? true : false, false);
        } else if (e === null && searchHistoryInput === "") {
          setDateRange(e);
          setOrderHistoryDetails([]);
        } else if (e === null && searchHistoryInput !== "") {
          setDateRange(e);
          setOrderHistoryDetails([]);
          searchOrderHistory("customer", searchHistoryInput, 0, false);
        }
      }}
      className="salesHistory-datePicker-sm"
    />
  );

  const handleExpand = (record) => {
    const nextExpandedKeys = [...expandedRowKeys];
    const index = nextExpandedKeys.indexOf(record.key);
    if (index > -1) {
      nextExpandedKeys.splice(index, 1);
    } else {
      nextExpandedKeys.push(record.key);
    }
    setExpandedRowKeys(nextExpandedKeys);
  };

  const handleQtyChange = (value, record) => {
    if (value <= record.stockOnHand) {
      if (value <= record.remainingQty + record.qtyToReverse) {
        const updatedData = selectedReverseOrderDetails.map((item) => {
          if (item.key === record.key) {
            return {
              ...item,
              qtyToReverse: value,
              remainingQty: record.qtyproduction - record.reverseQty - value,
            };
          }
          return item;
        });
        setSelectedReverseOrderDetails(updatedData);
      } else {
        {
          message.warning(`${t("sales_return_error")}`);
        }
      }
    } else {
      message.warning("Return Qty can't be more than Stock quantity !");
    }
  };

  const reverseProductionColumn = [
    {
      title: "Product Name",
      dataIndex: "productName",
      width: "20%",
    },
    {
      title: "Batch No",
      dataIndex: "batchNo",
      width: "15%",
    },
    {
      title: "Stock Qty",
      dataIndex: "stockOnHand",
      width: "15%",
      render: (text, record) => {
        return <div style={{ textAlign: "center" }}>{record?.stockOnHand?.toFixed(record?.uomData[0]?.stdprecision)}</div>;
      },
    },
    {
      title: "Qty Produced",
      dataIndex: "qtyproduction",
      width: "15%",
      render: (text, record) => {
        return <div style={{ textAlign: "center" }}>{record?.qtyproduction?.toFixed(record?.uomData[0]?.stdprecision)}</div>;
      },
    },
    {
      title: "Remaining Qty",
      dataIndex: "remainingQty",
      width: "15%",
      render: (text, record) => {
        return <div style={{ textAlign: "center" }}>{record?.remainingQty?.toFixed(record?.uomData[0]?.stdprecision)}</div>;
      },
    },
    {
      title: "Qty to Reverse",
      dataIndex: "qtyToReverse",
      width: "15%",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            icon={<MinusOutlined style={{ fontSize: "15px" }} />}
            style={{
              width: "30px",
              height: "28px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: 6,
              marginRight: "15px",
              paddingLeft: 6,
              borderRadius: "5px",
              paddingTop: 0,
            }}
            onClick={() => handleQtyChange(record.qtyToReverse - 1, record)}
          />
          <Input
            type="number"
            ref={qtyRef}
            value={record.qtyToReverse}
            min={0}
            style={{ width: "50px", textAlign: "center" }}
            onPressEnter={(e) => e.preventDefault()}
            onChange={(e) => handleQtyChange(e.target.value, record)}
          />
          <Button
            icon={<PlusOutlined style={{ fontSize: "15px", color: "#fff" }} />}
            style={{
              width: "30px",
              height: "28px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: 6,
              marginLeft: "15px",
              paddingLeft: 6,
              backgroundColor: "#2F3856",
              borderRadius: "5px",
            }}
            onClick={() => handleQtyChange(record.qtyToReverse + 1, record)}
          />
        </div>
      ),
    },
  ];

  const print = async (record) => {
    const json = `{
      "printer_type": "thermal",
      "printer_id": "POS123",
      "document": {
        "type": "barcode_label",
        "content": [
          {
            "type": "barcode",
            "value": "${record?.upc}",
            "format": "CODE128",
            "align": "center",
            "height": 100,
            "width": 2
          },
          {
            "type": "text",
            "value": "${record?.productValue}/${record?.ediCode}/${record?.productName}",
            "align": "center",
            "font": "bold",
            "size": "medium"
          },
          {
            "type": "text",
            "value": "Price: ${record?.salePrice}",
            "align": "center",
            "font": "normal",
            "size": "medium"
          },
          {
            "type": "text",
            "value": "${record?.batchNo}",
            "align": "center",
            "font": "normal",
            "size": "medium"
          }
        ]
      },
      "settings": {
        "copies": ${record?.qtyproduction},
        "cut_after_print": true,
        "paper_width": "80mm",
        "density": "high"
        }
      }`;
    Axios.post(`${printerURL}labelprinter`, json, {
      headers: {
        "Content-Type": "application/xml; charset=UTF-8",
        Accept: "application/xml",
      },
    })
      .then(() => {
        console.log("BOM Print success!");
      })
      .catch((response) => {
        console.log("BOM Print failed!", response);
      });
  };

  return (
    <div>
      {showOrderHistory && (
        <>
          <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh", display: reverseOrderDetails ? "none" : "block" }}>
            <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
              <Col span={8}>
                <img
                  src={Back}
                  id="sm-salesHistory-back"

                  tabIndex={0}
                  onClick={() => {
                    setFocusedRowIndex(null);
                    setShowOrderHistory(false);
                    setOrderHistoryDetails([]);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setFocusedRowIndex(null);
                      setShowOrderHistory(false);
                      setOrderHistoryDetails([]);
                    }
                  }}
                  style={{
                    height: "3vw",
                    paddingBottom: "0.7em",
                    cursor: "pointer",
                  }}
                />
                <span
                  style={{
                    paddingRight: "1vw",
                    color: "#0F0718",
                    fontSize: "1.8em",
                    fontWeight: 500,
                    marginLeft: "0.5em",
                  }}
                >
                  Production Order History
                </span>
              </Col>
              <Col
                span={8}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Search for Document Number/UPC/Product"
                  prefix={<img src={CustomerSearch} style={{ width: "1.4em" }} alt="" />}
                  id="sm-salesHistory-customer"
                  className="salesHistory-input-sm"
                  tabIndex={1}
                  onPressEnter={(e) => {
                    if (e.target.value !== "") {
                      searchOrderHistory("customer", e.target.value, 0, dateRange?.length > 0 ? true : false);
                    }
                  }}
                  onChange={async (e) => {
                    setSearchHistoryInput(e.target.value);
                    if (e.target.value === "" && dateRange?.length === 0) {
                      setOrderHistoryDetails([]);
                    } else if (e.target.value === "" && dateRange?.length !== 0) {
                      await setSearchHistoryInput("");
                      searchOrderHistory("orderDateSearchKey", dateRange, 0, false);
                    }
                  }}
                />
              </Col>
              <Col
                span={8}
                style={{
                  paddingRight: "",
                  paddingLeft: "1.5vw",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Popover
                 content={content}
                  trigger="click" visible={isPopoverVisible} 
                  // onVisibleChange={(visible) => setPopoverVisible(visible)}
                  onVisibleChange={(visible) => {
                    setPopoverVisible(visible);
                    if (visible) {
                      setTimeout(() => {
                        const datePickerElement = document.querySelector('.salesHistory-datePicker-sm');
                        const inputs = datePickerElement?.querySelectorAll('.ant-picker-input input');
                        if (inputs && inputs.length > 0) {
                          inputs[0].focus();
                        }
                      }, 100);
                    }
                  }}
                  >
                  <Button
                    icon={<CalendarOutlined style={{ paddingLeft: "0.5rem" }} />}
                    className="popover-trigger-button"
                    style={{
                      border: "none",
                      width: "27vw",
                      display: "flex",
                      alignItems: "center",
                      color: "#929098",
                      fontWeight: 400,
                      fontSize: "1em",
                      fontFamily: "Inter",
                    }}
                    onClick={handleButtonClick}
                    ref={dateRef}
                    tabIndex={2}
                  >
                    {getDisplayDate()}
                  </Button>
                </Popover>
                {filtersFlag ? (
                  <Button
                  tabIndex={3}
                    onClick={() => {
                      setOrderHistoryDetails([]);
                      setFiltersFlag(false);
                      setDateRange([]);
                      setSearchHistoryInput("");
                      setDateRange(null);
                    }}
                    onFocus={(e) => {
                      e.target.style.outline = "1px solid #40a9ff";
                      e.target.style.boxShadow = "0 0 5px #40a9ff"
                    }}
                    onBlur={(e) => {
                      e.target.style.outline = "none";
                    }}
                    style={{ border: "none", padding: "4px 10px" }}
                  >
                    <img src={clearFilters} alt="clear" />
                  </Button>
                ) : null}
              </Col>
            </Row>
            <br />
            <Row style={{ padding: "0 1.5em" }}>
              <Table
                className="sm-cart-table"
                ref={tableBodyRef}
                style={{
                  width: "100%",
                  overflowY: "auto",
                  border: "none",
                  fontSize: "1.5vw",
                  borderRadius: "7px",
                  height: "84vh",
                  padding: "0 1em",
                  background: "#fff",
                  boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                }}
                // onRow={(record) => ({
                //   onClick: () => handleExpand(record),
                // })}
                onRow={(record, index) => ({
                  onClick: () => { handleExpand(record), setFocusedRowIndex(index) },
                  onKeyDown: (e) => {
                    if (index === focusedRowIndex) {
                      handleKeyDown(e);
                    }
                  }
                })}
                pagination={false}
                columns={orderHistoryColumns}
                dataSource={orderHistoryDetails}
                onExpand={handleExpand}
                expandable={{
                  expandedRowKeys,
                  onExpand: handleExpand,
                  expandedRowRender: (record) => (
                    <div
                      style={{
                        padding: "1% 4% 1% 4%",
                        backgroundColor: "#f3f4f9",
                      }}
                    >
                      <Row>
                        <Col span={21}>
                          {record?.ioproducts && record?.ioproducts?.length > 0 ? (
                            <>
                              <Row>
                                <Col span={12} className="td-nested-title-sm">
                                  Component
                                </Col>
                                <Col span={3} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                                  Qty
                                </Col>
                                <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                                  Unit Price
                                </Col>
                                <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "right" }}>
                                  Total Price
                                </Col>
                              </Row>
                              <Row style={{ paddingBottom: "0.5vw" }}>
                                {record.ioproducts.map((e) => {
                                  return (
                                    <>
                                      <Col span={12} className="td-nested-description">
                                        {e.product}
                                      </Col>
                                      <Col
                                        span={3}
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {e.actualqty.toFixed(e?.uomData[0]?.stdprecision) || 0}
                                      </Col>
                                      <Col
                                        span={1}
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "400",
                                        }}
                                      />
                                      <Col
                                        span={3}
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {e?.salePrice?.toFixed(pricePrecision) || 0}
                                      </Col>
                                      <Col span={2} style={{ textAlign: "center" }} />
                                      <Col
                                        span={3}
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {e?.totalPrice?.toFixed(pricePrecision) || 0}
                                      </Col>
                                    </>
                                  );
                                })}
                              </Row>
                              <p
                                style={{
                                  width: "96%",
                                  marginLeft: "0%",
                                  borderTop: "1.3px solid rgba(52, 40, 44, 0.55)",
                                  marginBottom: "0px",
                                }}
                              />
                              <Row style={{ marginBottom: "1%" }}>
                                <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
                                  <span className="td-total">SUB TOTAL</span>
                                </Col>
                                <Col
                                  span={3}
                                  style={{
                                    fontSize: "1em",
                                    borderBottom: "1px solid rgba(52, 40, 44, 0.55)",
                                    textAlign: "right",
                                    paddingTop: "4px",
                                  }}
                                >
                                  <span className="td-total">
                                    {record.ioproducts && record.ioproducts.length > 0
                                      ? record.ioproducts.reduce((sum, item) => sum + parseFloat(item.totalPrice || 0), 0).toFixed(2)
                                      : "0.00"}
                                  </span>
                                </Col>
                              </Row>
                            </>
                          ) : null}
                        </Col>
                        <Col
                          span={3}
                          className="td-bottons"
                          style={{
                            fontSize: "1vw",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {record?.ioproducts && record?.reverse === "N" && record?.ioproducts?.length > 0 && posConfig?.enableReverseProduction === "Y" && (
                            <p
                              id="sm-salesHistory-printer"
                              style={{
                                cursor: "pointer",
                                padding: "5.5% 12%",
                                borderRadius: "5px",
                                border: "1px solid #fff",
                                backgroundColor: "#fff",
                                fontSize: "1em",
                                fontWeight: 500,
                                color: "#0F0718",
                                width: "7rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={async () => {
                                setReverseOrderDetails(true);
                                record.qtyToReverse = 0;
                                record.remainingQty = record.qtyproduction - record.reverseQty;
                                let uomData = [];
                                if (record.uomId) {
                                  await db.productUom
                                    .where("csUomId")
                                    .equals(record.uomId)
                                    .toArray()
                                    .then((uom) => {
                                      if (uom.length > 0) {
                                        uomData = uom;
                                      }
                                    });
                                }
                                record.uomData = uomData;
                                setSelectedReverseOrderDetails([record]);
                              }}
                            >
                              <img
                                src={Retrieve}
                                style={{
                                  height: "1vw",
                                  paddingRight: "0.4vw",
                                  cursor: "pointer",
                                }}
                              />
                              Reverse
                            </p>
                          )}
                          <p
                            id="sm-salesHistory-printer"
                            style={{
                              cursor: "pointer",
                              padding: "5.5% 12%",
                              borderRadius: "5px",
                              border: "1px solid #fff",
                              backgroundColor: "#fff",
                              fontSize: "1em",
                              fontWeight: 500,
                              color: "#0F0718",
                              width: "7rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => print(record)}
                          >
                            <img
                              src={printerIcon}
                              style={{
                                height: "1vw",
                                paddingRight: "0.4vw",
                                cursor: "pointer",
                              }}
                            />
                            Print
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ),
                  rowExpandable: (record) => record.name !== "Not Expandable",
                  expandIcon: ({ expanded, onExpand, record }) => (
                    <span id="sm-salesHistory-expand" onClick={(e) => onExpand(record, e)}>
                      {expanded ? <DownOutlined style={{ color: "#929098" }} /> : <RightOutlined style={{ color: "#929098" }} />}
                    </span>
                  ),
                }}
                rowClassName={(record, index) => index === focusedRowIndex ? 'focused-row' : ''}
              />
            </Row>
          </div>
          <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh", display: !reverseOrderDetails ? "none" : "block" }}>
            <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
              <Col span={6}>
                {/* <img
                  src={Back}
                  id="sm-salesReturn"
                  onClick={() => {
                    setReverseOrderDetails(false);
                  }}
                  style={{
                    height: "3vw",
                    paddingBottom: "0.7em",
                    cursor: "pointer",
                  }}
                /> */}
                <span
                  style={{
                    paddingRight: "1vw",
                    color: "",
                    fontSize: "1.8em",
                    fontWeight: 500,
                    marginLeft: "0.5em",
                    color: "#0F0718",
                  }}
                >
                  Reverse Production
                </span>
              </Col>
            </Row>
            <br />
            <Row style={{ padding: "0 1.5em 1.5em 1.5em", fontSize: "1vw" }}>
              <Col span={5} style={styles.screenStyles.returnPurchase.docNo}>
                Document No: {selectedReverseOrderDetails.length > 0 ? selectedReverseOrderDetails[0].documentno : 0}
              </Col>
              <Col span={5} style={styles.screenStyles.returnPurchase.docNo}>
                Date : {selectedReverseOrderDetails?.length > 0 ? selectedReverseOrderDetails[0]?.date : ""}
              </Col>
              <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
                Unit Price : {selectedReverseOrderDetails?.length > 0 ? selectedReverseOrderDetails[0].salePrice.toFixed(pricePrecision) : 0}
              </Col>
              <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
                Total Price : {selectedReverseOrderDetails?.length > 0 ? selectedReverseOrderDetails[0].totalPrice.toFixed(pricePrecision) : 0}
              </Col>
            </Row>
            <Row style={{ padding: "0 1.5em", fontSize: "1vw" }}>
              <Table
                className="sm-cart-table-return"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  border: "none",
                  fontSize: "1.1vw",
                  borderRadius: "7px",
                  height: "43vh",
                  padding: "0 1em",
                  background: themeJSON.productTableBGColor || "#fff",
                  boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                }}
                pagination={false}
                dataSource={selectedReverseOrderDetails}
                columns={reverseProductionColumn}
              />
            </Row>
            <Row style={{ paddingTop: "2vh" }}>
              <Col span={9}></Col>
              <Col span={4} />
              <Col span={11}>
                <Row style={{ fontSize: "1vw" }}>
                  <Col span={11} offset={1}>
                    <p style={styles.screenStyles.returnPurchase.returnItems}>Reverse Qty: </p>
                    <p style={styles.screenStyles.returnPurchase.returnAmount}>Reverse Amount Total:</p>
                  </Col>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <p style={styles.screenStyles.returnPurchase.returnItems}>
                      {" "}
                      {selectedReverseOrderDetails?.length > 0 ? selectedReverseOrderDetails[0].qtyToReverse.toFixed(3) : 0}
                    </p>
                    <p style={styles.screenStyles.returnPurchase.returnAmount}>
                      {" "}
                      {selectedReverseOrderDetails?.length > 0 ? selectedReverseOrderDetails[0].totalPrice.toFixed(pricePrecision) : 0}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: "9vh", fontSize: "1vw" }}>
                  <Col span={8} />
                  <Col span={8}>
                    <Button
                      style={{
                        width: "85%",
                        height: "5.5vh",
                        fontSize: "1.2em",
                        fontWeight: "500",
                        borderColor: "#fff",
                        color: "#0F0718",
                        borderRadius: "7px",
                        border: "1px solid rgba(146, 144, 152, 0.5)",
                        marginLeft: "2.5vw",
                      }}
                      onClick={() => {
                        setReverseOrderDetails(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={1} />
                  <Col span={8}>
                    <Button
                      style={{
                        width: "85%",
                        height: "5.5vh",
                        fontSize: "1.2em",
                        fontWeight: "500",
                        backgroundColor: "#2F3856",
                        color: "#fff",
                        borderRadius: "7px",
                        marginLeft: "1vw",
                      }}
                      disabled={selectedReverseOrderDetails[0]?.qtyToReverse > 0 ? false : true}
                      onClick={() => {
                        if (
                          selectedReverseOrderDetails[0].qtyToReverse <= selectedReverseOrderDetails[0].stockOnHand &&
                          selectedReverseOrderDetails[0].qtyToReverse <= selectedReverseOrderDetails[0].remainingQty + selectedReverseOrderDetails[0].qtyToReverse
                        ) {
                          localStorage.setItem("reverseOrderData", JSON.stringify(selectedReverseOrderDetails));
                          setReverseOrderData(selectedReverseOrderDetails);
                          setShowOrderHistory(false);
                          setOrderHistoryDetails([]);
                          setSearchHistoryInput("");
                          setReverseOrderDetails(false);
                          setSelectedReverseOrderDetails([]);
                          setDateRange([]);
                          setFiltersFlag(false);
                          setExpandedRowKeys([]);
                        } else {
                          {
                            message.warning(`${t("sales_return_error")}`);
                          }
                        }
                      }}
                      className="focusDashboardCard"
                    >
                      Reverse
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Modal
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={[
              <Button key="ok" type="primary" onClick={() => setIsVisible(false)}>
                OK
              </Button>,
            ]}
            centered
          >
            <p>product will not proceed for reverse</p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default BOMOrderHistory;
